import { TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { cn } from '@/helpers/cn';
import { FlowTemplateCard } from './components/FlowTemplateCard';
import { FlowTemplateCategoryIcon } from './components/FlowTemplateCategoryIcon';
import { FLOW_CATEGORIES } from '@/consts/flow-categories';
import { useFlowTemplates } from '@/api/flow/get-flow-templates';
import { FlowTemplateCategory } from '@/models/flow-template.interface';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useParams, useSearchParams } from 'react-router-dom';
import { useProject } from '@/api/projects/get-project.ts';
import { usePermissions } from '@/api/permission/get-permissions.ts';
import { CreateBlankFlowButton } from './components/CreateBlankFlowButton.tsx';
import { CreateBlankFlowTemplateButton } from './components/CreateBlankFlowTemplateButton.tsx';

export function FlowsLibrary() {
  const { projectId } = useParams();
  const { project } = useProject(Number(projectId));
  const { canCreateFlowsLibrary } = usePermissions();
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      search: searchParams.get('query') ?? '',
      category: '',
    },
    validationSchema: object({
      search: string(),
      category: string(),
    }),
    onSubmit: () => {},
  });

  const { flowTemplates } = useFlowTemplates(formik.values.search, formik.values.category);

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: projectId
          ? [
              { label: 'Projects', href: buildUrl([Urls.PROJECTS]) },
              { label: project?.name ?? '', href: buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: project?.id }) },
              { label: 'Flows library', href: '#' },
            ]
          : [{ label: 'Flows library', href: '#' }],
        icon: '/folder-black.svg',
        rightAddons: [
          <CreateBlankFlowButton key={projectId} />,
          <>{canCreateFlowsLibrary && <CreateBlankFlowTemplateButton />}</>,
        ],
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Flows library</title>
      </Helmet>

      <div className="flex items-start gap-8">
        <FormikProvider value={formik}>
          <div className="flex min-w-64 flex-col gap-3">
            <div className="relative">
              <TextInput
                {...getDfFlowbiteFieldProps('search', formik)}
                placeholder="Search"
                icon={HiSearch}
              />
            </div>

            <ul className="flex flex-col gap-1">
              {[
                { label: 'All categories', value: '' },
                { label: 'My flows', value: FlowTemplateCategory.MY },
                ...FLOW_CATEGORIES,
              ].map((category) => (
                <li
                  key={category.value}
                  onClick={() => formik.setFieldValue('category', category.value)}
                  className={cn(
                    'flex cursor-pointer gap-2 rounded-lg px-3 py-2 hover:bg-gray-50',
                    formik.values.category === category.value ? 'bg-gray-ultra-light' : '',
                  )}
                >
                  <FlowTemplateCategoryIcon category={category.value} />
                  <span>{category.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </FormikProvider>

        <div className="grid grow grid-cols-3 gap-6">
          {flowTemplates?.map((flowTemplate) => (
            <FlowTemplateCard
              key={flowTemplate.id}
              template={flowTemplate}
            />
          ))}
        </div>
      </div>
    </PageWithHeaderLayout>
  );
}

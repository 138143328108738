import { ReactNode, useEffect, useState } from 'react';
import { useTestABPaymentHooks } from '@/guards/TestABPaymentHooks.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';

import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { mutate } from 'swr';
import { TagManagerEvent, UserEvent } from '@/api/events/model';
import { useTriggerEvent } from '@/api/events/api';

export const ABTestGuard = ({ children }: { children: ReactNode }) => {
  const { hasUserFullAccess, isLoading } = useTestABPaymentHooks();
  const [isFullAccessCheckedTwice, setIsFullAccessCheckedTwice] = useState(false);
  const { trigerUserEvent, triggerDataLayer } = useTriggerEvent();
  const { openModal } = useGlobalModal();

  useEffect(() => {
    // Refresh user stripe data after 3 seconds to make sure stripe had time to inform
    // backend about user subscription status after user started the trial
    setTimeout(async () => {
      await mutate('stripe/my-products');
      setIsFullAccessCheckedTwice(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (isLoading || !isFullAccessCheckedTwice) return;

    if (hasUserFullAccess) {
      void trigerUserEvent(UserEvent.user_started_with_free);
      void triggerDataLayer(TagManagerEvent.set_ab_test_variant, { ab_test_variant: 'A' });
    } else {
      void trigerUserEvent(UserEvent.user_started_with_trial);
      void triggerDataLayer(TagManagerEvent.set_ab_test_variant, { ab_test_variant: 'B' });
      openModal(MODALS.TestABPayment);
    }
  }, [hasUserFullAccess, isLoading, isFullAccessCheckedTwice]);

  if (isLoading) return null;

  return hasUserFullAccess ? (
    <>{children}</>
  ) : (
    <>
      {isFullAccessCheckedTwice ? (
        <div
          className="fixed left-0 top-0 h-screen w-screen"
          style={{ backgroundImage: 'url(/main-gradient-lg.jpg)', backgroundSize: 'cover' }}
        ></div>
      ) : null}
    </>
  );
};

import { ExpandLeftNavButton } from '@/layouts/MainLayout/components/ExpandLeftNavButton.tsx';
import { AgentPanelButton } from '@/components/AgentPanel/AgentPanelButton.tsx';
import { ReactNode } from 'react';
import { Breadcrumb } from '@/models/breadcrumb.interface';
import { Breadcrumbs } from '@/components/Breadcrumbs.tsx';
import React from 'react';

export interface PageHeaderProps {
  icon: string;
  breadcrumbs: Breadcrumb[];
  leftAddons?: ReactNode[];
  rightAddons?: ReactNode[];
}

export function PageHeader({ icon, breadcrumbs, leftAddons, rightAddons }: PageHeaderProps) {
  return (
    <header className="page-header">
      <div className="flex items-center gap-3">
        <ExpandLeftNavButton />
        <div className="rounded bg-gray-ultra-light p-1.5">
          <img
            src={icon}
            width={20}
            height={20}
            alt=""
          />
        </div>
        <div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        {leftAddons?.map((addon, index) => <React.Fragment key={index}>{addon}</React.Fragment>)}
      </div>

      <div className="flex items-center gap-3">
        <AgentPanelButton />

        {rightAddons?.map((addon, index) => <React.Fragment key={index}>{addon}</React.Fragment>)}
      </div>
    </header>
  );
}

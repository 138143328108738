import { useTriggerEvent } from '@/api/events/api';
import { TagManagerEvent } from '@/api/events/model';
import { usePlanLimits } from '@/api/pricing/get-plan-limit';
import { UpgradePlanForm } from '@/components/UpgradePlanForm.tsx';
import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum';
import { getPlanLimitForUpsellProduct } from '@/helpers/get-plan-limit-for-upsell-product';
import { useEffect } from 'react';

export const UpgradePlanModalBody = () => {
  const { triggerDataLayer } = useTriggerEvent();
  const { planLimits } = usePlanLimits();

  useEffect(() => {
    if (!planLimits) {
      return;
    }

    const limit = getPlanLimitForUpsellProduct(StripeUpsellProductName.DF_TOKEN, planLimits);
    if (limit.currentValue == 0) {
      triggerDataLayer(TagManagerEvent.plan_limits_reached, {});
    }
  }, [planLimits]);

  return (
    <div className="grid grid-cols-[45%,minmax(0,1fr)] gap-10">
      <img
        src="/upgrade-plan-picture.jpg"
        className="sticky top-0 h-full max-h-[calc(90svh-1.5rem)] w-full rounded-l-xl object-cover"
        alt=""
      />

      <UpgradePlanForm />
    </div>
  );
};

import { Node } from '@tiptap/core';

export default Node.create({
  name: 'iframe',
  group: 'block',
  defaultOptions: {
    allowFullscreen: true,
    HTMLAttributes: {
      class: 'iframe-wrapper',
    },
  },
  addAttributes() {
    return {
      src: {
        default: null,
      },
      frameborder: {
        default: 0,
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]];
  },
});

'use client';

import { HocuspocusProvider } from '@hocuspocus/provider';

import {
  AiWriter,
  AiImage,
  BlockquoteFigure,
  CharacterCount,
  Color,
  Document,
  Dropcursor,
  Emoji,
  Figcaption,
  FileHandler,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  TableOfContents,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  emojiSuggestion,
  Columns,
  Column,
  TaskItem,
  TaskList,
  DfGenOutput,
  DFUserInput,
  AiTools,
} from '.';

import { Image } from '@tiptap/extension-image';
import { ImageUpload } from './ImageUpload';
import { TableOfContentsNode } from './TableOfContentsNode';
import { Video } from './Video/video';
import { useUploadImage } from '../lib/api';
import Iframe from './Iframe/iframe';

interface ExtensionKitProps {
  provider?: HocuspocusProvider | null;
  userId?: string;
  userName?: string;
  userColor?: string;
}

export const ExtensionKit = ({ provider, userId, userName = 'Maxi' }: ExtensionKitProps) => {
  const { uploadImage } = useUploadImage();

  return [
    Document,
    Columns,
    TaskList,
    Iframe,
    Video.configure(),
    Image.configure({
      allowBase64: true,
      inline: true,
    }),
    DfGenOutput.configure(),
    DFUserInput.configure(),
    TaskItem.configure({
      nested: true,
    }),
    AiWriter.configure({
      authorId: userId,
      authorName: userName,
    }),
    AiTools().configure({
      authorId: userId,
      authorName: userName,
    }),
    AiImage.configure({
      authorId: userId,
      authorName: userName,
    }),
    Column,
    Selection,
    Heading.configure({
      levels: [1, 2, 3, 4, 5, 6],
    }),
    HorizontalRule,
    StarterKit.configure({
      document: false,
      dropcursor: false,
      heading: false,
      horizontalRule: false,
      blockquote: false,
      history: false,
      codeBlock: false,
    }),
    TextStyle,
    FontSize,
    FontFamily,
    Color,
    TrailingNode,
    Link.configure({
      openOnClick: false,
    }),
    Highlight.configure({ multicolor: true }),
    Underline,
    CharacterCount.configure({ limit: 500000 }),
    TableOfContents,
    TableOfContentsNode,
    ImageUpload.configure({
      clientId: provider?.document?.clientID,
    }),
    ImageBlock,
    FileHandler.configure({
      allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
      onDrop: (currentEditor, files, pos) => {
        files.forEach(async (file) => {
          const url = await uploadImage(file);

          currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run();
        });
      },
      onPaste: (currentEditor, files) => {
        files.forEach(async (file) => {
          const url = await uploadImage(file);

          return currentEditor
            .chain()
            .setImageBlockAt({ pos: currentEditor.state.selection.anchor, src: url })
            .focus()
            .run();
        });
      },
    }),
    Emoji.configure({
      enableEmoticons: true,
      suggestion: emojiSuggestion,
    }),
    TextAlign.extend({
      addKeyboardShortcuts() {
        return {};
      },
    }).configure({
      types: ['heading', 'paragraph'],
    }),
    Subscript,
    Superscript,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Typography,
    Placeholder.configure({
      includeChildren: true,
      showOnlyCurrent: false,
      placeholder: () => '',
    }),
    SlashCommand,
    Focus,
    Figcaption,
    BlockquoteFigure,
    Dropcursor.configure({
      width: 2,
      class: 'ProseMirror-dropcursor border-black',
    }),
  ];
};

export default ExtensionKit;

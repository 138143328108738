import { marked } from 'marked';
import { ToolName } from 'src/libs/tools/type.model';
import { removeInstagramUrlLimit } from './remove-instagra-url-limitation';
import { convertVideoLinkToEmbed } from './convert-video-link-to-embed';

interface SmartScrapperOutput {
  results: {
    content: {
      url: string;
      raw_text: string;
      google_research: boolean;
      summarization: string | null;
      color_stats: string | null;
    }[];
  };
}

interface DynamicResearchOutput {
  content: string;
  query: string;
}

interface ResearcherCompanyOutput {
  company_overview: string;
  product_information: string;
  brand_guidelines: string;
  marketing_effort: string;
}

interface TavilyOutput {
  query: string;
  follow_up_questions: string | null;
  answer: string | null;
  images: [];
  results: { title: string; url: string; content: string; score: number }[];
}

interface ResearcherGeneralOutput {
  positions: { specificPrompt: string; value: string }[];
}

interface TiktokScrapperOutput {
  authorMeta: {
    nickName: string;
    video: number;
    fans: number;
    region: string;
  };
  hashtags: { name: string }[];
  webVideoUrl: string;
}
interface YoutubeScrapperOutput {
  title: string;
  channelUsername: string;
  viewCount: number;
  likes: number;
  commentsCount: number;
  hashtags: string[];
  text: string;
  url: string;
}

interface LinkedinScrapperOutput {
  pictureUrl: string;
  firstName: string;
  lastName: string;
  occupation: string;
  geoLocationName: string;
  geoCountryName: string;
  followersCount: number;
  skills: string[];
  positions: { title: string; companyName: string }[];
  educations: { degreeName: string; schoolName: string }[];
  companyName: string;
  companyLinkedinUrl: string;
}

interface RedditScrapperOutput {
  title?: string;
  url: string;
  description?: string;
  communityName?: string;
  username?: string;
  link?: string;
  rules?: { description: string }[];
  body?: string;
}

interface FacebookScrapperOutput {
  pageInfo: {
    page: {
      name: string;
    };
    pageCategory: string;
    pageProfileUri: string;
    pageVerification: string;
    igFollowers: string;
  };
  title?: string;
  pageName?: string;
  inputUrl?: string;
  pageUrl?: string;
  coverPhotoUrl?: string;
  user?: {
    name: string;
  };
  categories?: string[];
  likes?: number;
  shares?: number;
  intro?: string;
  text?: string;
  about_me?: {
    text: string;
  };
  media: {
    photo_image: {
      uri: string;
    };
  }[];
}

export type Output = SmartScrapperOutput | DynamicResearchOutput | ResearcherCompanyOutput | ResearcherGeneralOutput;

export const researchgenOutputFormater = (output: Output, toolName: ToolName): string => {
  switch (toolName) {
    case ToolName.SMART_SCRAPPER:
      return formatSmartScraper(output as SmartScrapperOutput);

    case ToolName.DYNAMIC_RESEARCHER:
      return formatDynamicResearch(output as DynamicResearchOutput);

    case ToolName.RESEARCHER:
      return formatResearcher(output as ResearcherCompanyOutput | ResearcherGeneralOutput);

    case ToolName.TAVILY:
      return formatTavily(output as unknown as TavilyOutput);

    case ToolName.INSTAGRAM_SCRAPPER:
      return formatInstagramScrapper(output as unknown as Record<string, []>[]);

    case ToolName.TIKTOK_SCRAPPER:
      return formatTiktokScrapper(output as unknown as TiktokScrapperOutput[]);

    case ToolName.YOUTUBE_SCRAPPER:
      return formatYoutubeScrapper(output as unknown as YoutubeScrapperOutput[]);

    case ToolName.LINKEDIN_SCRAPPER:
      return formatLinkedinScrapper(output as unknown as LinkedinScrapperOutput[]);

    case ToolName.REDDIT_SCRAPPER:
      return formatRedditScrapper(output as unknown as RedditScrapperOutput[]);
    case ToolName.FACEBOOK_SCRAPPER:
      return formatFacebookScrapper(output as unknown as FacebookScrapperOutput[]);
  }

  if (typeof output === 'string') return marked(output as string) as string;

  return output as unknown as string;
};

function formatSmartScraper(output: SmartScrapperOutput) {
  let text = '';

  output.results.content.forEach((element) => {
    text += `<h2>${element.url}</h2>`;

    if (element.summarization) {
      text += `<p>${marked(element.summarization)}</p>`;
    } else {
      text += `<p>${element.raw_text}</p>`;
    }

    if (element.color_stats) {
      text += `<h3>Colors</h3>`;
      text += `<p>${marked(element.color_stats)}</p>`;
    }
  });

  return text;
}
function formatDynamicResearch(output: DynamicResearchOutput): string {
  return marked(output.content) as string;
}
function formatResearcher(output: ResearcherCompanyOutput | ResearcherGeneralOutput): string {
  let text = '';

  if ('company_overview' in output) {
    text += `<h2>Company Overview</h2>`;
    text += `<p>${marked(output.company_overview)}</p>`;
  }

  if ('product_information' in output) {
    text += `<h2>Product Information</h2>`;
    text += `<p>${marked(output.product_information)}</p>`;
  }

  if ('positions' in output) {
    output.positions.forEach((element) => {
      text += `<h2>${element.specificPrompt}</h2>`;
      text += `<p>${element.value}</p>`;
    });
  }

  return text;
}
function formatTavily(output: TavilyOutput): string {
  let text = `<h2> Your Query: ${output.query} </h2>`;

  if (output.follow_up_questions) {
    text += `<h3>Follow Up Questions</h3>`;
    text += `<p>${output.follow_up_questions}</p>`;
  }

  if (output.answer) {
    text += `<h3>Answer</h3>`;
    text += `<p>${output.answer}</p>`;
  }

  if (output.images.length > 0) {
    text += `<h3>Images</h3>`;
    output.images.forEach((element) => {
      text += `<img class="my-6" src="${element}">`;
    });
  }

  if (output.results.length > 0) {
    text += `<h3>Results</h3>`;

    output.results.forEach((element) => {
      text += `<h4>${element.title}</h4>`;
      text += `<p>${element.content}</p>`;
      text += `<a href="${element.url}">${element.url}</a>`;
      text += `<p>score: ${element.score}</p>`;
    });
  }

  return marked(text) as string;
}
function formatInstagramScrapper(output: Record<string, []>[]): string {
  let element = '';

  if (output[0]?.error || !output || output.length === 0) {
    return `This data is private or not available`;
  }

  for (const item of output) {
    element += `<h2>${item.inputUrl ?? item.url}</h2>`;

    if (item.fullName) {
      element += `<p><b>Full Name:</b> ${item?.fullName ?? ''}</p>`;
    }

    if (item.followsCount) {
      element += `<p><b>Follows:</b> ${item?.followsCount ?? ''}</p>`;
    }

    if (item.businessCategoryName) {
      element += `<p><b>Business Category:</b> ${item?.businessCategoryName ?? ''}</p>`;
    }

    if (item.postsCount) {
      element += `<p><b>Posts:</b> ${item?.postsCount ?? ''}</p>`;
    }

    if (item.highlightReelCount) {
      element += `<p><b>Highlight Reels:</b> ${item?.highlightReelCount ?? ''}</p>`;
    }

    if (item?.caption) {
      element += `<p><b>Caption:</b> ${item?.caption ?? ''}</p>`;
    }

    if (item.videoUrl) {
      element += `<p><b>Video:</b></p>
      <iframe width="320" height="240" src= src=${removeInstagramUrlLimit(item.videoUrl as unknown as string)} ></iframe>`;
    }

    if (item?.hashtags?.length > 0) {
      element += `<p><b>Hashtags:</b> ${item?.hashtags ?? ''}</p>`;
    }

    if (item?.mentions?.length > 0) {
      element += `<p><b>Mentions:</b> ${item?.mentions ?? ''}</p>`;
    }

    if (item.displayUrl) {
      const images = item?.images as string[];
      element +=
        `<p><b>Images:</b></p><img src=${removeInstagramUrlLimit(item.displayUrl as unknown as string)}>` +
        images?.map((image) => `<img src=${removeInstagramUrlLimit(image)}>`).join('');
    }

    if (item?.likesCount) {
      element += `<p><b>Likes:</b> ${item?.likesCount ?? ''}</p>`;
    }

    if (item?.latestComments?.length > 0) {
      const comments = item?.latestComments as { ownerUsername: string; text: string }[];
      element +=
        `<p><b>Comments:</b></p>` +
        comments?.map((comment) => `<p><b>author:</b> ${comment.ownerUsername}</p><p>${comment.text}</p>`).join('');
    }

    if (item?.taggedUsers?.length > 0) {
      const taggedUsers = item?.taggedUsers as { full_name: string }[];
      element += `<p><b>Tagged Users:</b></p>` + taggedUsers?.map((user) => `<p>${user.full_name}</p>`).join('');
    }

    if (item?.relatedProfiles?.length > 0) {
      const relatedProfiles = item?.relatedProfiles as { username: string; full_name: string }[];
      element +=
        `<p><b>Related Profiles:</b></p>` + relatedProfiles?.map((profile) => `<p>${profile.full_name}</p>`).join('');
    }

    if (item?.lastPosts?.length > 0) {
      element += formatInstagramScrapper(item.lastPosts);
    }
  }

  return marked(element) as string;
}

function formatTiktokScrapper(output: TiktokScrapperOutput[]): string {
  let element = '';

  output.forEach((item) => {
    element += `<h2>${item.authorMeta.nickName}</h2>`;
    element += `<p><b>Video Count:</b> ${item?.authorMeta?.video ?? '0'}</p>`;
    element += `<p><b>Follower Count:</b> ${item?.authorMeta?.fans ?? '0'}</p>`;
    element += `<p><b>Region:</b> ${item?.authorMeta?.region ?? ''}</p>`;
    element += `<p><b>Hashtags:</b></p>` + `<p>${item?.hashtags?.map((hashtag) => hashtag.name).join(', ')}</p>`;
    element += `<p><b>Video:</b></p> <iframe width="320" height="240" src=${convertVideoLinkToEmbed(item?.webVideoUrl) ?? ''}></iframe>`;
  });

  return element;
}

function formatYoutubeScrapper(output: YoutubeScrapperOutput[]) {
  let element = '';

  output.forEach((item) => {
    element += `<h2>${item.title}</h2>`;

    element += `<p><b>Author:</b> ${item?.channelUsername ?? ''}</p>`;
    element += `<p><b>Views:</b> ${item?.viewCount ?? '0'}</p>`;
    element += `<p><b>Likes:</b> ${item?.likes ?? '0'}</p>`;
    element += `<p><b>Comments:</b> ${item?.commentsCount ?? '0'}</p>`;
    element += `<p><b>Hashtags:</b></p>` + `<p>${item?.hashtags?.map((hashtag) => hashtag).join(', ')}</p>`;
    element += `<p><b>Description:</b> ${item?.text ?? ''}</p>`;
    element += `<p><b>Video:</b></p> <iframe width="320" height="240" src=${convertVideoLinkToEmbed(item?.url) ?? ''}></iframe>`;
  });

  return element;
}

function formatLinkedinScrapper(output: LinkedinScrapperOutput[]) {
  let element = '';

  output.forEach((item) => {
    element += `<h2><img src=${item.pictureUrl}>${item.firstName} ${item.lastName}</h2>`;
    element += `<p><b>Title:</b> ${item?.occupation ?? ''}</p>`;
    element += `<p><b>Country:</b> ${item.geoLocationName}, ${item?.geoCountryName ?? ''}</p>`;
    element += `<p><b>Followers count:</b> ${item?.followersCount ?? ''}</p>`;
    element += `<p><b>Skills:</b> ${item?.skills.join(', ') ?? ''}</p>`;

    element +=
      `<p><b>Position:</b></p>` +
      item?.positions?.map((position) => `<p>${position.title}, ${position.companyName}</p>`).join('');

    element +=
      `<p><b>Education:</b></p>` +
      item?.educations?.map((education) => `<p>${education.degreeName}, ${education.schoolName}</p>`).join('');

    element += `<p><b>Company:</b> ${item?.companyName ?? ''}</p>`;
    element += `<p><b>Company URL:</b> ${item?.companyLinkedinUrl ?? ''}</p>`;
  });

  return element;
}

function formatRedditScrapper(output: RedditScrapperOutput[]) {
  let element = '';

  output.forEach((item) => {
    element += `<h2>${item.title ? item.title + '-' : ''}  ${item.url}</h2>`;
    element += `<p><b>Description:</b> ${item.description ?? ''}</p>`;
    element += `<p><b>Community name:</b> ${item.communityName ?? ''}</p>`;

    if (item.username) {
      element += `<p><b>Username:</b> ${item.username ?? ''}</p>`;
    }

    if (item.link) {
      element += `<p><b>Link:</b> ${item.link ?? ''}</p>`;
    }

    if (item.rules) {
      element += `<p><b>Rules:</b></p>` + item.rules.map((rule) => `<p>${rule.description}</p>`).join('');
    }

    if (item.body) {
      element += `<p><b>Body:</b>${item.body}</p>`;
    }
  });

  return element;
}

function formatFacebookScrapper(output: FacebookScrapperOutput[]) {
  console.log(output);
  let element = '';

  output.forEach((item) => {
    if (item.pageInfo.page) {
      element += `<h2>${item.pageInfo.page.name}</h2>`;
    }

    if (item.pageInfo.pageCategory) {
      element += `<p><b>Category:</b> ${item.pageInfo.pageCategory ?? ''}</p>`;
    }

    if (item.pageInfo.pageProfileUri) {
      element += `<p><b>Url:</b> ${item.pageInfo.pageProfileUri ?? ''}</p>`;
    }

    if (item.pageInfo.pageVerification) {
      element += `<p><b>Page verification:</b> ${item.pageInfo.pageVerification ?? ''}</p>`;
    }

    if (item.pageInfo.igFollowers) {
      element += `<p><b>Instagram name:</b> ${item.pageInfo.igFollowers ?? ''}</p>`;
    }

    if ((item.title || item.pageName) && (item.inputUrl || item.pageUrl)) {
      element += `<h2>${item.title ?? item.pageName} - ${item.inputUrl ?? item.pageUrl}</h2>`;
    }

    if (item.coverPhotoUrl) {
      element += `<img src=${item.coverPhotoUrl} alt="Cover Photo">`;
    }

    if (item?.user?.name) {
      element += `<p><b>Authonr:</b> ${item.user.name ?? ''}</p>`;
    }

    if (item.categories) {
      element += `<p><b>Categories:</b> ${item.categories.join(', ') ?? ''}</p>`;
    }

    if (item.likes) {
      element += `<p><b>Likes:</b> ${item.likes ?? ''}</p>`;
    }

    if (item.shares) {
      element += `<p><b>Shares:</b> ${item.shares ?? ''}</p>`;
    }

    if (item.intro) {
      element += `<p><b>Intro:</b> ${item.intro ?? ''}</p>`;
    }

    if (item.text) {
      element += `<p><b>Text:</b> ${item.text ?? ''}</p>`;
    }

    if (item.about_me) {
      element += `<p><b>About:</b> ${item.about_me.text ?? ''}</p>`;
    }

    if (item?.media?.length > 0) {
      element += `<p><b>Media:</b></p>` + item.media.map((media) => `<img src=${media.photo_image.uri}>`).join('');
    }
  });

  return element;
}
